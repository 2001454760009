<template>
  <div>
    <div class="outgoing-detail" v-if="detail != null">
      <Toast />

      <div class="card">
        <table style="width: 100%">
          <thead>
            <!-- HEADER -->
            <div class="card">
              <div class="p-grid">
                <div class="p-col-1 image">
                  <img :src="ModeLogo" alt="logo" />
                </div>
                <div
                  class="p-col-10"
                  style="margin-top: -10px; margin-left: -10px"
                >
                  <h4 style="font-size: 15px; line-height: 0">
                    <b>MODE FASHION GROUP</b>
                  </h4>
                  <h6 style="font-size: 11px; line-height: 0">
                    {{ outlet.name }}
                  </h6>
                  <h6 style="font-size: 11px; line-height: 0">
                    Telp. (061) 6643780
                  </h6>
                </div>
              </div>
            </div>
            <div class="border-header" style="margin-bottom: 30px"></div>
            <!-- akhir header -->
          </thead>
          <tbody>
            <h4 class="title" style="margin-bottom: 20px; margin-top: -20px">
              <b style="font-size: 14px">STOK OPNAME KATEGORI {{ cat_code }}</b>
            </h4>

            <!-- <div class="card">
              <div
                class="p-grid p-p-flex-column"
                style="margin-bottom: -20px; font-size: 11px"
              >
                <div class="p-col-7 detail-bon">
                  <div class="p-grid">
                    <div class="p-col-3">
                      <div>Kategori</div>
                    </div>
                    <div class="p-col-8">
                      <div>: {{ cat_code }}</div>
                    </div>
                  </div>
                </div>
                <div class="p-col-4">
                  <div class="p-grid">
                    <div class="p-col-6" style="margin-left: -40px">
                      <div>Tanggal Bon</div>
                    </div>
                    <div class="p-col-6">
                      <div>
                        :
                        {{
                          moment(detail.summary.doc_date).format('DD-MM-YYYY')
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="p-grid p-p-flex-column"
                style="margin-bottom: -25px; font-size: 11px"
              >
                <div class="p-col-7 detail-bon">
                  <div class="p-grid" v-if="detail.summary.type == 'RETURN'">
                    <div class="p-col-4">
                      <div>Tujuan Gudang</div>
                    </div>
                    <div class="p-col-8" style="margin-left: -30px">
                      <div>: {{ detail.summary.warehouse_name }}</div>
                    </div>
                  </div>
                  <div class="p-grid" v-else>
                    <div class="p-col-4">
                      <div>Tujuan Outlet</div>
                    </div>
                    <div class="p-col-8" style="margin-left: -30px">
                      <div>: {{ detail.summary.outlet_name_destination }}</div>
                    </div>
                  </div>
                </div>
                <div class="p-col-4">
                  <div class="p-grid">
                    <div class="p-col-6" style="margin-left: -40px">
                      <div>Status</div>
                    </div>
                    <div class="p-col-6">
                      <div v-if="detail.summary.status == 0" style="color: red">
                        : Tertunda
                      </div>
                      <div
                        v-if="detail.summary.status == 1"
                        style="color: green"
                      >
                        : Terkonfirmasi
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="p-grid">
              <div class="p-col-11 p-m-auto">
                <table class="table-bordered text-center detail-bon">
                  <thead class="thead-light" style="font-size: 11px">
                    <tr>
                      <th class="text-center">No</th>
                      <th class="text-center">Kode Barang</th>
                      <th class="text-center">Nama Barang</th>
                      <th class="text-center">Stok Barang</th>
                      <th class="text-center">Stock Opname</th>
                      <th class="text-center">Selisih</th>
                      <th class="text-center">Harga Jual</th>
                    </tr>
                  </thead>
                  <tbody style="font-size: 11px">
                    <tr v-for="(an_item, k) in detail" :key="k">
                      <td>{{ k + 1 }}</td>
                      <td>{{ an_item.code }}</td>
                      <td>{{ an_item.Item_name }}</td>
                      <td>{{ an_item.stock }}</td>
                      <td>{{ an_item.stock_opname }}</td>
                      <td>{{ an_item.difference }}</td>
                      <td>{{ an_item.price | currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </tbody>
        </table>
      </div>
      <!-- akhir class card -->
    </div>
  </div>
</template>

<script>
import ModeLogo from '../../assets/images/mode_logo.png'

export default {
  // props: ['detail', 'cat_code'],

  data() {
    return {
      loading: true,
      products: null,
      expandedRows: [],
      confirmDialog: false,
      ModeLogo: ModeLogo,
      outlet: null,
      detail: null,
      cat_code: null,
      code: null,
      show_not_opname: null
    }
  },
  mounted() {
    this.cat_code = this.$route.query.cat_code
    this.outlet = this.$route.query.outlet_id
    this.code = this.$route.query.code
    this.show_not_opname = this.$route.query.show_not_opname
    this.getAuthUser()
  },
  methods: {
    getAuthUser() {
      this.getOutlets()
      this.printPge()
    },

    async getOutlets() {
      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/outlets/${this.outlet}`
        )
        if (response.status === 200) {
          this.outlet = response.data.data.outlets
        }
      } catch (error) {
        console.error('Error fetching outlets:', error)
      }
    },

    async printPge() {
      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/stock-opname/print/items?cat_code=${this.cat_code}&code=${this.code}&show_not_opname=${this.show_not_opname}`
        )
        if (response.status === 200) {
          this.detail = response.data.data.items
          setTimeout(function () {
            window.print()
          }, 500)
          setTimeout(function () {
            window.history.back()
          }, 500)
        }
      } catch (error) {
        console.error('Error fetching print page details:', error)
      }
    }
  }
}
</script>
<style scoped lang="scss">
table {
  -fs-table-paginate: paginate;
}
.outgoing-detail {
  font-family: monospace;
  width: 100%;
  margin-top: -50px;
  margin-left: auto; /* to center the div */
  margin-right: auto; /* to center the div */
  box-sizing: border-box;
  overflow: hidden;
}

.border-header {
  border-top: 2px solid black;
  width: 95%;
  margin-top: -4vh;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.image {
  margin-top: -7px;
  width: 63px;
}

.title {
  text-align: center;
}

.detail-bon {
  margin-left: auto;
  margin-right: auto;
}

th,
td {
  padding: 5px;
}
</style>
