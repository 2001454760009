<template>
  <div class="container p-grid">
    <Toast />
    <div class="section p-col-10 p-d-flex p-flex-column" style="height: 100vh">
      <div class="p-grid p-fluid p-mb-2">
        <div class="p-col-4">
          <InputText
            type="text"
            v-model="search.barcode"
            placeholder="Barcode"
            :disabled="!is_open_shift"
            @focus="$event.target.select()"
            v-on:keyup.enter="searchBarcode()"
            v-shortkey.focus="['f2']"
            ref="barcode"
          />
        </div>
        <div class="p-col-4">
          <AutoComplete
            :suggestions="salespersons_filtered"
            @complete="searchSalesperson($event)"
            v-model="salespersons_selected"
            placeholder="Pramuniaga"
            field="name"
            :dropdown="true"
            :disabled="!is_open_shift"
            id="pramuniaga"
          ></AutoComplete>
        </div>
        <div class="p-col-4">
          <AutoComplete
            :suggestions="customers"
            @complete="searchCustomer()"
            v-model="customers_selected"
            placeholder="Pelanggan"
            :field="
              slotProps => (slotProps.name ? slotProps.name : slotProps.phone)
            "
            @keyup="searchCustomer()"
            :dropdown="true"
            :disabled="!is_open_shift"
            id="customer"
          >
            <template #item="slotProps">
              <div
                v-if="slotProps.index == 0"
                style="width: 50%; padding: 5px 0"
                class="add-customer"
              >
                <Button
                  @click="createCustomer()"
                  label="Tambah Customer"
                  icon="pi pi-plus"
                  class="p-button-primary p-mb-3"
                  :disabled="!is_open_shift || total_billed_amount < 0"
                />
              </div>
              <div v-if="slotProps.item">
                <span
                  v-if="slotProps.index == 0"
                  class="p-text-bold p-d-flex p-jc-center"
                  >Daftar Customer <br
                /></span>
                <span v-if="slotProps.item.name"
                  >{{ slotProps.item.name }} - </span
                >{{ slotProps.item.phone }}
              </div>
            </template>
          </AutoComplete>
          <div v-if="isError.customers">
            <div
              style="width: 100%; padding: 15px 0; border: 1px solid #ccc"
              class="add-customer"
            >
              <p
                style="position: relative; bottom: 5px"
                @click="createCustomer()"
              >
                Data tidak ditemukan, buat baru?
                <i
                  class="pi pi-arrow-circle-right"
                  @click="createCustomer()"
                  style="font-size: 1.3rem; position: relative; top: 3px"
                ></i>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-grid p-fluid p-mx-0"
        style="font-size: 18px; width: 100%; overflow: auto"
      >
        <div class="p-col-12 p-px-0 p-mx-0" style="height: 65vh">
          <table class="table">
            <th></th>
            <tbody>
              <tr
                v-for="(item, k) in cart"
                :key="k"
                class="table-items"
                style="cursor: pointer"
                @click="getCodeCatalog(item.code)"
              >
                <td style="width: 5%">{{ item.iterasi }}.</td>
                <td style="width: 55%">{{ item.code }} - {{ item.name }}</td>
                <td style="width: 10%">
                  <Button
                    type="button"
                    icon="pi pi-minus"
                    class="p-button-outlined"
                    style="width: 30px; height: 30px"
                    @click="minQty(item)"
                  ></Button>
                  <span style="margin: 0px 2px">{{ item.qty }}</span>
                  <Button
                    type="button"
                    icon="pi pi-plus"
                    class="p-button-outlined"
                    style="width: 30px; height: 30px"
                    @click="addQty(item)"
                  ></Button>
                </td>
                <td style="width: 16%">
                  <InputText
                    placeholder="%"
                    style="
                      width: 40px;
                      height: 30px;
                      text-align: center;
                      font-size: 18px;
                    "
                    v-model="item.discount"
                    :maxlength="3"
                    id="item-diskon"
                    @input="changeItemDiscount(item)"
                  />
                  %
                </td>
                <td class="p-text-right" style="width: 20%">
                  {{ (item.qty * item.price) | currency }}
                </td>
                <td style="width: 5%">
                  <Button
                    type="button"
                    icon="pi pi-trash"
                    class="p-button-outlined"
                    style="width: 30px; height: 30px"
                    @click="deleteItem(item)"
                  ></Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="p-grid p-fluid" style="margin-top: 1rem">
        <div class="p-col-4 p-py-0">
          <div class="card-qty" v-if="is_open_shift">
            <p class="card-qty-title">
              Qty : {{ qty_total }} | Jenis: {{ lengthCart }}
            </p>
          </div>
          <Card class="p-my-0">
            <template #content v-if="opened_at">
              <div class="p-grid">
                <div class="p-col-3">
                  <img src="~@/assets/images/user.png" alt="user" />
                </div>
                <div class="p-col-9">
                  <h4 class="p-m-0">{{ name }}</h4>
                  <p class="p-m-0">{{ outlet.name }}</p>
                  <p class="p-mb-2">{{ outlet.address }}</p>
                  <h5
                    class="p-m-0"
                    style="font-size: 15px"
                    v-if="is_open_shift"
                  >
                    {{ opened_at | shortDate }} | {{ hours }} : {{ minutes }} :
                    {{ seconds }}
                  </h5>
                </div>
              </div>
            </template>
          </Card>
        </div>
        <div class="p-col-8">
          <div class="p-grid">
            <div class="p-col-6">
              <p>Sub-Total</p>
            </div>
            <div class="p-col-6 p-text-right">
              <p>{{ sub_total | currency }}</p>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-6">
              <p>Diskon</p>
            </div>
            <div class="p-col-6 p-text-right">
              <p>{{ total_discount | currency }}</p>
            </div>
          </div>
          <div class="p-grid" v-if="voucher_amount > 0">
            <div class="p-col-6">
              <p style="color: green">Voucher</p>
            </div>
            <div class="p-col-6 p-text-right">
              <p style="color: green">
                <b>{{ voucher_amount | currency }} </b>
              </p>
            </div>
          </div>
          <div class="p-grid" v-if="discount_transaction_value > 0">
            <div class="p-col-6">
              <p style="color: green">Diskon Cashier</p>
            </div>
            <div class="p-col-6 p-text-right">
              <p style="color: green">
                <b>{{ discount_transaction_value | currency }} </b>
              </p>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-6">
              <h5>
                <b style="font-size: 2.5rem">Total</b>
              </h5>
            </div>
            <div class="p-col-6 p-text-right">
              <h5>
                <b style="font-size: 2.5rem">{{
                  total_billed_amount | currency
                }}</b>
              </h5>
            </div>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
              <InputNumber
                placeholder="Jumlah diskon"
                mode="decimal"
                id="jumlah-diskon"
                locale="id-ID"
                ref="discount_transaction_percentage"
                v-model="discount_transaction_percentage"
                @focus="$event.target.select()"
                :disabled="
                  !is_open_shift ||
                  discount_type === null ||
                  (discount_type != 'Rupiah' && discount_type != 'Persen')
                "
                @keyup.prevent="changeDiscountTransaction()"
              />
            </div>
            <div class="p-col-6">
              <Dropdown
                v-model="discount_type"
                :options="discount_types"
                placeholder="Tipe diskon"
                :disabled="sub_total == '0'"
                @change="changeDiscountTransaction()"
              />
            </div>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
              <InputText
                type="text"
                placeholder="Kode voucher"
                @focus.native="$event.target.select()"
                v-model="voucher_code"
                :disabled="!is_open_shift || sub_total == '0'"
              />
            </div>
            <div class="p-col-6">
              <Button
                @click="validateVoucher()"
                label="Cek voucher"
                class="p-button-outlined"
                :disabled="
                  !is_open_shift || sub_total == '0' || voucher_code.length <= 0
                "
                v-show="!cancelVoucher"
              />
              <Button
                @click="clearVoucher()"
                label="Batal voucher"
                class="p-button-outlined p-button-danger"
                :disabled="!is_open_shift || sub_total == '0'"
                v-show="cancelVoucher"
              />
            </div>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
              <Button
                v-shortkey="['shift', 's']"
                @shortkey="saveTransaction(false)"
                @click="saveTransaction(false)"
                label="Simpan"
                class="p-button-outlined"
                :disabled="!is_open_shift"
              />
            </div>
            <div class="p-col-6">
              <Button
                @click="saveTransaction(true)"
                label="Bayar"
                class="p-button-primary"
                :disabled="!is_open_shift || total_billed_amount < 0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section p-col-2 p-md-2 p-fluid p-px-3">
      <div class="p-pt-2 p-fluid" style="height: 30vh" v-if="is_open_shift">
        <div class="snapshot-card" v-if="snapshot.code">
          <div class="card-body">
            <p class="snapshot-code">{{ snapshot.code }}</p>
            <img
              class="snapshot-img"
              :src="snapshot.thumbnail ? snapshot.thumbnail : no_image"
              :alt="snapshot.name"
            />
            <p class="snapshot-name-price">
              {{ snapshot.name }} - {{ snapshot.price | currency }}
            </p>
          </div>
        </div>
        <div class="snapshot-card" v-else>
          <div class="card-body">
            <p class="snapshot-code code-kosong">Belum Ada Barang</p>
          </div>
        </div>
      </div>
      <div class="p-pt-3" style="height: 65vh; width: 100%">
        <div class="p-col" v-if="is_open_shift">
          <Button
            @click="openCatalogList(true)"
            v-shortkey.push="['shift', 'k']"
            @shortkey="openCatalogList(true)"
            icon="pi pi-file"
            label="Katalog"
            class="p-button-outlined"
            style="height: 45px"
          ></Button>
        </div>
        <div class="p-col" v-if="is_open_shift">
          <Button
            icon="pi pi-heart"
            label="Hadiah"
            class="p-button-outlined"
            style="height: 45px"
            @click="openDialogGiftClaim(true)"
            v-shortkey.push="['shift', 'g']"
            @shortkey="openDialogGiftClaim(true)"
          ></Button>
        </div>
        <div class="p-col" v-if="is_open_shift">
          <Button
            @click="openTransactionList(true)"
            v-shortkey.push="['shift', 'p']"
            @shortkey="openTransactionList(true)"
            icon="pi pi-shopping-cart"
            label="Tertunda"
            class="p-button-outlined"
            style="height: 45px"
          ></Button>
        </div>
        <div class="p-col" v-if="is_open_shift">
          <Button
            icon="pi pi-dollar"
            label="Refund"
            class="p-button-outlined"
            style="height: 45px"
            @click="openDialogRefund(true)"
            v-shortkey.push="['home']"
            @shortkey="openDialogRefund(true)"
          />
        </div>
        <div class="p-col" v-if="is_open_shift">
          <Button
            icon="pi pi-print"
            label="Struk"
            class="p-button-outlined"
            style="height: 45px"
            @click="openDialogStruk(true)"
            v-shortkey.push="['shift', 'r']"
            @shortkey="openDialogStruk(true)"
          />
        </div>
        <div class="p-col" v-if="is_open_shift">
          <Button
            icon="pi pi-list"
            label="Riwayat"
            class="p-button-outlined"
            style="height: 45px"
            @click="openHistoryList(true)"
            v-shortkey.push="['shift', 'h']"
            @shortkey="openHistoryList(true)"
          />
        </div>
        <div class="p-col" v-if="is_open_shift">
          <Button
            icon="pi pi-chart-bar"
            label="Ringkasan"
            class="p-button-outlined"
            style="height: 45px"
            @click="openDialogRingkasan(true)"
            v-shortkey.push="['shift', 'm']"
            @shortkey="openDialogRingkasan(true)"
          />
        </div>
        <div class="p-col" v-if="is_pending_shift">
          <Button
            @click="confirmOpenShift"
            icon="pi pi-check-circle"
            label="Mulai Shift"
            class="p-button-outlined"
            style="height: 45px"
          ></Button>
        </div>
        <div class="p-col">
          <Button
            @click="openDialogLogout(true)"
            v-shortkey.push="['esc']"
            @shortkey="openDialogLogout(true)"
            icon="pi pi-sign-out"
            label="Logout"
            class="p-button-outlined"
            style="height: 45px"
          ></Button>
        </div>
      </div>
      <Dialog
        :visible.sync="dialog.shift_warning"
        :style="{ width: '450px' }"
        header="Warning"
        :modal="true"
      >
        <div class="confirmation-content">
          <span>
            <b>Anda belum memiliki shift</b>
          </span>
        </div>
      </Dialog>

      <Dialog
        :visible.sync="dialog.open_shift"
        :style="{ width: '400px' }"
        header="Mulai Shift"
        :modal="true"
      >
        <div class="p-grid p-col-12">
          <div class="p-col-4">
            <b>Uang Awal:</b>
          </div>
          <div class="p-col-8 p-text-right" style="font-size: 26px">
            <b>{{ opening_balance | currency }}</b>
          </div>
        </div>

        <template #footer>
          <Button
            label="Mulai"
            class="p-button-outlined"
            @click="openShift()"
          ></Button>
        </template>
      </Dialog>

      <Dialog
        :visible.sync="dialog.payment"
        :style="{ width: '500px' }"
        header="Pembayaran"
        :modal="true"
      >
        <div class="p-grid p-col-12">
          <div class="p-col-4">
            <b>TOTAL</b>
          </div>
          <div class="p-col-8 p-text-right" style="font-size: 26px">
            <b>{{ total_billed_amount | currency }}</b>
          </div>

          <div class="p-col-12 p-mb-2 p-grid">
            <table class="table table-responsive">
              <thead class="thead-light">
                <tr>
                  <th>Metode Bayar</th>
                  <th>Jumlah Bayar</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(an_item, k) in payment_temp" :key="k">
                  <td class="p-col-4">
                    <Dropdown
                      v-model="an_item.payment_method_id"
                      :options="payment_methods"
                      @input="checkPaymentMethod(k, an_item.payment_method_id)"
                      optionLabel="name"
                      ref="payment_method"
                      placeholder="Select Payment Method"
                    />
                  </td>
                  <td class="p-col-5">
                    <InputNumber
                      mode="decimal"
                      :id="'data-price-' + k"
                      placeholder="0"
                      locale="id-ID"
                      v-model="an_item.amount"
                      @focus="$event.target.select()"
                      ref="payment"
                      @keyup.prevent="calculateChange(k)"
                      :disabled="payment_pay"
                    />
                    <small class="p-error" v-if="isError.payment_amount">{{
                      error_msg.payment_amount
                    }}</small>
                  </td>
                  <td class="p-col-3">
                    <Button
                      type="button"
                      icon="pi pi-minus"
                      class="p-button-outlined"
                      style="width: 30px; height: 30px"
                      v-if="payment_temp.length > 1"
                      @click="removePaymentMethod(an_item)"
                    ></Button>
                    <Button
                      type="button"
                      icon="pi pi-plus"
                      class="p-button-outlined"
                      style="width: 30px; height: 30px; margin-left: 0.5rem"
                      v-if="payment_temp.length == k + 1"
                      @click="addPaymentMethod()"
                    ></Button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="p-col"
              style="margin-top: -2rem"
              v-if="payment_temp.length > 1"
            >
              <label for="total_bayar">Total Bayar</label>
              <InputNumber
                v-model="total_bayar"
                placeholder="Total Bayar"
                :readonly="true"
                :disabled="true"
              />
            </div>
          </div>

          <div class="p-col-12 p-mb-4 p-grid">
            <div class="p-col-6">
              <label for="infaq">Infaq</label>
              <InputText
                v-model="infaq_payment_amount"
                mode="decimal"
                placeholder="0"
                :maxlength="4"
                locale="id-ID"
                @focus="$event.target.select()"
                :disabled="round_up_return_amount != 0"
                @input="calculateInfaq"
              />
              <small class="p-error" v-if="isError.infaq">{{
                error_msg.infaq
              }}</small>
            </div>
            <div class="p-col-6">
              <label for="pembulatan">Pembulatan</label>
              <InputText
                v-model="round_up_return_amount"
                mode="decimal"
                placeholder="0"
                :maxlength="3"
                locale="id-ID"
                :disabled="infaq_payment_amount != 0"
                @focus="$event.target.select()"
                @input="calculateRoundUp"
              />
            </div>
          </div>
          <div class="p-col-4">
            <b>Kembalian</b>
          </div>
          <div class="p-col-8 p-text-right" style="font-size: 18px">
            <b>{{ money_changes | currency }}</b>
          </div>
        </div>

        <template #footer>
          <button
            type="button"
            class="btn btn-primary"
            style="width: 100px"
            @click="payTransaction"
            v-shortkey="['enter']"
            @shortkey="handleEnter"
            :disabled="
              total_billed_amount < 0 ||
              isError.payment_amount ||
              loading.payment
            "
          >
            Bayar
            <span v-if="loading.payment">
              <i class="pi pi-spin pi-spinner" style="font-size: 12px"></i>
            </span>
          </button>
        </template>
      </Dialog>

      <!-- dialog kembalian setelah cetak struk -->
      <Dialog
        :visible.sync="dialog.change"
        :style="{ width: '400px' }"
        header="Transaksi Berhasil"
        :modal="true"
        :closable="false"
      >
        <div class="p-grid p-col-12">
          <div class="p-col-4">
            <b>Kembalian:</b>
          </div>
          <div class="p-col-8 p-text-right" style="font-size: 26px">
            <b>{{ money_changes | currency }}</b>
          </div>
        </div>

        <template #footer>
          <Button
            label="OK"
            class="p-button-text"
            @click="changeReset"
            v-shortkey.focus="['enter']"
            @focus="changeReset"
          />
        </template>
      </Dialog>

      <!-- dialog audit date tidak sesuai -->
      <Dialog
        :visible.sync="dialog.audit_date"
        :style="{ width: '500px' }"
        header="WARNING"
        :modal="true"
        :closable="false"
      >
        <div class="p-grid p-col-12 text-center">
          <b style="font-size: 18px"
            >Audit date tidak sesuai, silahkan tutup shift hari ini</b
          ><br />
          <b style="font-size: 18px">dan buka shift kembali</b>
        </div>

        <template #footer>
          <Button
            label="OK"
            class="p-button-text"
            @click="closeDialogAuditDate"
          />
        </template>
      </Dialog>

      <!-- dialog catalog -->
      <Dialog
        :visible.sync="dialog.catalog"
        :style="{ width: '50%' }"
        header="Daftar Katalog"
        :modal="true"
        class="p-fluid"
      >
        <div style="width: 100%; overflow: auto">
          <DataView
            :value="items"
            :lazy="true"
            :paginator="true"
            :rows="item_limit"
            :totalRecords="item_total"
            :layout="layout"
            :loading="loading.filter"
            @page="getItems($event)"
          >
            <template #header>
              <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-4 p-lg-4">
                  <InputText
                    v-model="filter.name"
                    placeholder="Nama"
                    @keyup="searchName"
                  />
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                  <InputText
                    v-model="filter.code"
                    placeholder="Barcode"
                    :maxlength="11"
                    @keyup="searchCode"
                    ref="barcode_catalog"
                  />
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                  <InputText
                    v-model="filter.supplier"
                    placeholder="Supplier"
                    @keyup="searchSupplier"
                  />
                </div>
              </div>
            </template>

            <template #empty>Data tidak tersedia.</template>
            <template #loading>Loading data item. Mohon tunggu.</template>

            <template #grid="slotProps">
              <div
                class="p-d-flex p-col-12 p-md-4 p-mt-2"
                style="cursor: pointer"
              >
                <div
                  class="product-grid-item card"
                  @click="loadCatalog(slotProps.data)"
                >
                  <div class="product-grid-item-top">
                    <div>
                      <i class="pi pi-bookmark product-code-icon"></i>
                      <span class="product-code" style="margin: 0rem 0.2rem">{{
                        slotProps.data.code
                      }}</span>
                    </div>
                    <span
                      class="product-code-discount"
                      v-if="slotProps.data.discount != '0'"
                    >
                      {{ slotProps.data.discount }}%</span
                    >
                  </div>
                  <div class="product-grid-item-content">
                    <div>
                      <img
                        :src="
                          slotProps.data.thumbnail
                            ? slotProps.data.thumbnail
                            : no_image
                        "
                        :alt="slotProps.data.name"
                      />
                    </div>
                    <div class="product-name">{{ slotProps.data.name }}</div>
                    <div
                      class="product-initPrice"
                      v-if="slotProps.data.price != slotProps.data.init_price"
                    >
                      {{ slotProps.data.init_price | currency }}
                    </div>
                  </div>
                  <div class="product-price">
                    {{ slotProps.data.price | currency }}
                  </div>
                  <div class="product-description">
                    {{ slotProps.data.supplier }}
                  </div>
                  <div class="product-stok">
                    Stok : {{ slotProps.data.stock }}
                  </div>
                  <div
                    class="product-description"
                    v-if="slotProps.data.docDate == '0001-01-01T00:00:00Z'"
                  >
                    -
                  </div>
                  <div class="product-description" v-else>
                    {{ slotProps.data.docDate.substr(0, 10) | formatDate }}
                  </div>
                </div>
              </div>
            </template>
          </DataView>
        </div>
      </Dialog>
      <!-- end -->

      <Dialog
        :visible.sync="dialog.pending"
        :style="{ width: '40%' }"
        header="Transaksi Tertunda"
        :modal="true"
        class="p-fluid"
      >
        <div class v-if="transactions.length == 0">
          <div class="p-col-12">
            <p>Data tidak ditemukan</p>
          </div>
        </div>
        <div class v-else>
          <div class="p-col-12">
            <table class="table table-responsive">
              <thead class="thead-light">
                <tr>
                  <th>Cashier</th>
                  <th>Customer</th>
                  <th>Voucher Refund</th>
                  <th>Tanggal</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(an_item, k) in transactions" :key="k">
                  <td>
                    {{ an_item.cashier.name }}
                  </td>
                  <td>
                    {{
                      an_item.customer.name
                        ? an_item.customer.name
                        : an_item.customer.phone
                    }}
                  </td>
                  <td>
                    {{
                      an_item.voucher.refund.code
                        ? an_item.voucher.refund.code
                        : '-'
                    }}
                  </td>
                  <td>
                    {{ an_item.trx_date | basicDateTime }}
                  </td>
                  <td>
                    <div>
                      <b-dropdown
                        text="Lanjutkan"
                        variant="primary"
                        class="m-2"
                      >
                        <b-dropdown-item @click="loadTransaction(an_item)"
                          >Yes</b-dropdown-item
                        >
                        <b-dropdown-item
                          href="#"
                          @click="unfinished_transaction_dialog = false"
                          >No</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Dialog>

      <!-- dialog temporary omset -->
      <Dialog
        :visible.sync="dialogOmset"
        :style="{ width: '300px' }"
        header="Total Omset"
        :modal="true"
        :closable="false"
      >
        <div class="p-grid p-col-12 text-center">
          <b style="font-size: 18px">Omset : {{ temporaryOmset | currency }}</b>
        </div>

        <template #footer>
          <Button
            label="Ok"
            class="p-button-text"
            @click="dialogOmset = false"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="dialog.confirmGiftClaim"
        :style="{ width: '450px' }"
        header="Konfirmasi"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> Apakah kamu yakin ingin mengakhiri transaksi barang? </span>
        </div>
        <template #footer>
          <Button
            label="Tidak"
            icon="pi pi-times"
            class="p-button-text"
            @click="dialog.confirmGiftClaim = false"
          />
          <Button
            label="Ya"
            icon="pi pi-check"
            class="p-button-text"
            @click="openLinkTheSameTab('gift-claim', {})"
            v-shortkey.push="['enter']"
            @shortkey="openLinkTheSameTab('gift-claim', {})"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="dialog.confirmRefund"
        :style="{ width: '450px' }"
        header="Konfirmasi"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> Apakah kamu yakin ingin mengakhiri transaksi barang? </span>
        </div>
        <template #footer>
          <Button
            label="Tidak"
            icon="pi pi-times"
            class="p-button-text"
            @click="dialog.confirmRefund = false"
          />
          <Button
            label="Ya"
            icon="pi pi-check"
            class="p-button-text"
            @click="openLinkTheSameTab('refund', {})"
            v-shortkey.push="['enter']"
            @shortkey="openLinkTheSameTab('refund', {})"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="dialog.confirmStruk"
        :style="{ width: '450px' }"
        header="Konfirmasi"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> Apakah kamu yakin ingin mengakhiri transaksi barang? </span>
        </div>
        <template #footer>
          <Button
            label="Tidak"
            icon="pi pi-times"
            class="p-button-text"
            @click="dialog.confirmStruk = false"
          />
          <Button
            label="Ya"
            icon="pi pi-check"
            class="p-button-text"
            @click="openLinkNewTab('receipt', {})"
            v-shortkey.push="['enter']"
            @shortkey="openLinkNewTab('receipt', {})"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="dialog.emptyStruk"
        :style="{ width: '450px' }"
        header="Konfirmasi"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span>Belum ada transaksi! </span>
        </div>
        <template #footer>
          <Button
            label="Ok"
            icon="pi pi-times"
            class="p-button-text"
            @click="dialog.emptyStruk = false"
            v-shortkey.push="['enter']"
            @shortkey="dialog.emptyStruk = false"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="dialog.history"
        :style="{ width: '50%' }"
        header="Riwayat"
        :modal="true"
        class="p-fluid"
      >
        <div class v-if="transaction_histories.length == 0">
          <div class="p-col-12">
            <p>Data tidak ditemukan</p>
          </div>
        </div>
        <div class v-else>
          <div class="p-col-12">
            <table class="table table-responsive">
              <thead class="thead-light">
                <tr>
                  <th>Cashier</th>
                  <th>Nomor Invoice</th>
                  <th>Tanggal</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(an_item, k) in transaction_histories" :key="k">
                  <td>
                    {{ an_item.cashier }}
                  </td>
                  <td>
                    {{ an_item.number }}
                  </td>
                  <td>
                    {{ an_item.date | basicDateTime }}
                  </td>
                  <td>
                    {{ an_item.amount | currency }}
                  </td>
                  <td>
                    <div>
                      <router-link
                        :to="{
                          name: 'history-receipt',
                          query: { id: an_item.id }
                        }"
                        target="_blank"
                      >
                        <Button label="Print" icon="pi pi-print" />
                      </router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Dialog>

      <Dialog
        :visible.sync="dialog.confirmRingkasan"
        :style="{ width: '450px' }"
        header="Konfirmasi"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> Apakah kamu yakin ingin mengakhiri transaksi barang? </span>
        </div>
        <template #footer>
          <Button
            label="Tidak"
            icon="pi pi-times"
            class="p-button-text"
            @click="dialog.confirmRingkasan = false"
          />
          <Button
            label="Ya"
            icon="pi pi-check"
            class="p-button-text"
            @click="openLinkTheSameTab('summary', {})"
            v-shortkey.push="['enter']"
            @shortkey="openLinkTheSameTab('summary', {})"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="dialog.confirmLogout"
        :style="{ width: '450px' }"
        header="Konfirmasi"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> Apakah kamu yakin ingin keluar? </span>
        </div>
        <template #footer>
          <Button
            label="Tidak"
            icon="pi pi-times"
            class="p-button-text"
            @click="dialog.confirmLogout = false"
          />
          <Button
            label="Ya"
            icon="pi pi-check"
            class="p-button-text"
            @click="logout"
            v-shortkey.push="['enter']"
            @shortkey="logout"
          />
        </template>
      </Dialog>
      <!-- create customer -->
      <Dialog
        :visible.sync="customer_dialog"
        :style="{ width: '450px' }"
        header="Tambah Pelanggan"
        :modal="true"
        class="p-fluid"
      >
        <div class="p-field p-mt-2">
          <label>Telepon</label>
          <InputText
            type="number"
            required
            ref="customer_phone"
            v-model="create_customer.phone"
            placeholder="Telepon"
            @keyup.prevent="isError.customer_phone = false"
          />
          <small class="p-error" v-if="isError.customer_phone">{{
            error_msg.customer_phone
          }}</small>
        </div>
        <template #footer>
          <Button
            label="Batal"
            class="p-button-outlined"
            @click="closeDialogCreateCustomer()"
          />
          <Button
            label="Tambah"
            class="p-button-info"
            @click="postCustomer()"
          />
        </template>
      </Dialog>
      <Dialog
        :visible.sync="customer_isExist_dialog"
        v-if="this.member_dialog_state"
        :style="{ width: '450px' }"
        header="Sudah Ada Member ?"
        :modal="true"
        class="p-fluid"
        @hide="setFocus()"
      >
        <div class="p-field p-mt-2 p-mb-10">
          <Button
            label="Ya, Sudah"
            class="p-button-info p-mt-2"
            @click="toggleCustomerExist"
          />
          <AutoComplete
            class="p-mt-2"
            :suggestions="customers"
            @complete="searchCustomer()"
            v-model="customers_selected"
            v-if="customerIsExist"
            placeholder="Pelanggan"
            :field="
              slotProps => (slotProps.name ? slotProps.name : slotProps.phone)
            "
            @keyup="searchCustomer()"
            :dropdown="true"
            :disabled="!is_open_shift"
            id="customer"
          >
            <template #item="slotProps">
              <div
                v-if="slotProps.index == 0"
                style="width: 50%; padding: 5px 0"
                class="add-customer"
              ></div>
              <div v-if="slotProps.item">
                <span v-if="slotProps.item.name"
                  >{{ slotProps.item.name }} - </span
                >{{ slotProps.item.phone }}
              </div>
            </template>
          </AutoComplete>
          <Button
            v-if="!customerIsExist"
            label="Belum, Ingin Buat"
            class="p-button-outlined p-mt-2"
            @click="createCustomer()"
          />
        </div>
        <template #footer>
          <Button
            label="Batal"
            class="p-button-outlined"
            @click="closeCustomerExistDialog"
          />
          <Button
            label="Simpan"
            v-if="customerIsExist"
            class="p-button-outlined"
            @click="closeCustomerExistDialog"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import noImage from '../../assets/images/no-image.png'

export default {
  props: ['voucher_code_used'],
  computed: {
    globalInvoiceId() {
      return this.payment.invoice_id
    },
    ...mapState(['user', 'isAuthenticated'])
  },
  created() {
    window.addEventListener('keydown', this.shortcutListener)
    window.addEventListener('keyup', e => {
      console.info(e.key)
    })
  },
  watch: {
    user() {
      this.getAuthUser()
      setInterval(() => this.setTime(), 1000)
    }
  },
  data() {
    return {
      today: new Date().toISOString().split('T')[0],
      name: '',
      outlet: [],
      outlet_id: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      snapshot: [],
      no_image: noImage,

      salespersons: [],
      salespersons_filtered: null,
      salespersons_selected: null,

      customers: [],
      customers_selected: null,
      customer_dialog: false,
      customer_isExist_dialog: false,
      customerIsExist: false,
      types: [{ type: 'REGULAR' }, { type: 'RESELLER' }],
      selected_type: null,

      create_customer: {
        name: '',
        email: '',
        phone: '',
        type: 'REGULAR'
      },

      items: [],
      item_limit: 9,
      item_total: 0,

      isError: {
        infaq: false,
        round_up: false,
        payment_method: false,
        discount_item: false,
        customers: false,
        customer_phone: false
      },

      error_msg: {
        infaq: '',
        payment_amount: '',
        discount_item: '',
        customer_phone: ''
      },

      search: {
        name: '',
        barcode: '',
        code: ''
      },

      cart: [],
      lengthCart: 0,
      iterasi: 0,

      qty_total: 0,
      sub_total: 0,
      total_discount: 0,
      billed_amount: 0,
      total_billed_amount: 0,

      discount_type: null,
      discount_types: ['Persen', 'Rupiah'],
      discount_transaction_percentage: null,
      discount_transaction_value: 0,

      transactions: [],
      transaction: {
        id: '',
        salesperson_id: null,
        cust_id: null,
        items: [],
        discount: null,
        voucher_code: ''
      },
      transaction_histories: [],

      voucher_code: '',
      voucher_amount: 0,
      cancelVoucher: false,

      total_bayar: 0,
      payment_temp: [
        {
          payment_method_id: null,
          amount: 0
        }
      ],
      payment: {
        invoice_id: 0,
        infaq: 0,
        payment: [],
        round_up: '',
        voucher_code: '',
        change: 0
      },

      dialog: {
        open_shift: false,
        shift_warning: false,
        pending: false,
        history: false,
        payment: false,
        change: false,
        catalog: false,
        confirmGiftClaim: false,
        confirmRefund: false,
        confirmStruk: false,
        emptyStruk: false,
        confirmRingkasan: false,
        confirmLogout: false,
        audit_date: false
      },
      member_dialog_state: false,
      payment_amount: 0,
      infaq_payment_amount: 0,
      round_up_return_amount: 0,
      money_changes: 0,

      opening_balance: 0,
      is_pending_shift: false,
      is_open_shift: false,
      opened_at: null,

      payment_methods: [],
      payment_method_selected: null,
      bank_account: null,
      store: {
        name: process.env.STORE_NAME,
        addr: process.env.STORE_ADDR,
        phone: process.env.STORE_PHONE
      },

      layout: 'grid',
      loading: {
        filter: false,
        payment: false
      },

      filter: {
        name: '',
        code: '',
        supplier: '',
        show_empty_stock: true
      },
      payment_pay: false,
      temporaryOmset: null,
      dialogOmset: false,
      enterPressed: false
    }
  },
  mounted() {
    this.getAuthUser()
    this.loadVoucher()
    this.getSalespersons()
    this.getItems()
    this.getPaymentMethods()
    this.checkAuditDate()
    setInterval(() => this.setTime(), 1000)
    setTimeout(this.setFocus, 1000)
  },

  methods: {
    getAuthUser() {
      if (this.user && this.user.shift) {
        this.name = this.user.name
        this.outlet_id = this.user.outlet_id
        this.opened_at = this.user.shift.opened_at
        this.is_pending_shift = this.user.shift.status === 'PENDING'
        this.is_open_shift = this.user.shift.status === 'OPEN'
        this.dialog.shift_warning = !(
          this.is_pending_shift || this.is_open_shift
        )
        this.member_dialog_state = this.user.show_member_dialog
        this.getOutlets()
        this.getTemporaryOmset()
        if (this.is_open_shift == true) {
          this.showCustDialogExist()
        }
      }
    },

    showCustDialogExist() {
      if (this.user.shift.status === 'OPEN') {
        this.customer_isExist_dialog = true
      }
    },

    toggleCustomerExist() {
      this.customerIsExist = !this.customerIsExist
    },

    closeCustomerExistDialog() {
      this.customer_isExist_dialog = false
      this.setFocus()
    },

    checkAuditDate() {
      if (this.user && this.user.shift && this.user.shift.audit_date) {
        let today = new Date()
        let audit_date = new Date(this.user.shift.audit_date)

        // check if the clock surpass 08:00 AM and it's a different date, give alert
        if (
          today.getDate() !== audit_date.getDate() &&
          today.getHours() >= 8 &&
          !this.shift_warning
        ) {
          this.dialog.audit_date = true
        }
      }
    },

    closeDialogAuditDate() {
      this.dialog.audit_date = false
    },

    setFocus() {
      this.$refs.barcode.$el.focus()
    },

    shortcutListener(e) {
      if (e.keyCode === 115) {
        // f4 = focus to discount item
        e.preventDefault()
        for (let i = 0; i < this.cart.length; i++) {
          document.getElementById('item-diskon').focus()
        }
      }
      if (e.keyCode === 116) {
        // f5 = refresh  or load page
        e.preventDefault()
        window.location.reload()
      }
      if (e.keyCode === 119) {
        // f8 = focus to field pramuniaga
        e.preventDefault()
        document.getElementById('pramuniaga').focus()
      }
      if (e.keyCode === 121) {
        //f10 = focus to field jumlah diskon
        e.preventDefault()
        document.getElementById('jumlah-diskon').focus()
      }
      if (e.keyCode === 32) {
        //space = button bayar
        e.preventDefault()
        if (this.total_billed_amount >= 0) {
          this.saveTransaction(true)
        }
      }
      if (e.keyCode === 13) {
        //enter = close and yes all dialog
        e.preventDefault()
        this.dialog.shift_warning = false
        // this.openCatalogList(false)
        this.openHistoryList(false)
        this.openTransactionList(false)
      }
      if (e.keyCode === 46) {
        // 46 is the keycode for 'Delete'
        e.preventDefault()
        this.member_dialog_state = false // Close the dialog
      }

      if (e.keyCode === 13) {
        //enter to close dialog temporary omset
        e.preventDefault()
        this.closeTemporaryOmset()
      }
    },

    getTemporaryOmset() {
      let audit_date
      if (this.opened_at) {
        audit_date = this.user.shift.audit_date
      }

      this.$http
        .get(`${process.env.VUE_APP_API_URL}/pos/omset/${audit_date}`)
        .then(response => {
          if (response.status === 200) {
            this.temporaryOmset = response.data.data.total_omset.total
          }
        })
    },

    showTemporaryOmset() {
      this.dialogOmset = true
    },

    closeTemporaryOmset() {
      this.dialogOmset = false
    },

    loadVoucher() {
      if (this.voucher_code_used) {
        this.voucher_code = this.voucher_code_used
        this.validateVoucher()
      } else {
        this.voucher_code = ''
      }
    },

    validateVoucher() {
      this.$http
        .post(`${process.env.VUE_APP_API_URL}/pos/vouchers/validation`, {
          voucher_code: this.voucher_code
        })
        .then(response => {
          if (response.data.code == 200) {
            this.voucher_amount = parseFloat(response.data.data.amount)
            this.cancelVoucher = true
            this.$toast.add({
              severity: 'success',
              summary: 'Voucher berhasil digunakan',
              life: 3000
            })
            this.calculateTotal(true)
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Voucher gagal digunakan',
              detail: 'Tolong, cek kembali kode voucher',
              life: 3000
            })
            return false
          }
        })
    },

    clearVoucher() {
      this.cancelVoucher = false
      this.voucher_code = ''
      this.voucher_amount = 0

      this.calculateTotal(true)
      this.calculateDiscount()
    },

    getOutlets() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/outlets/${this.outlet_id}`)
        .then(response => {
          if (response.status === 200) {
            this.outlet = response.data.data.outlets
            this.getCustomers('', this.outlet.phone)
          }
        })
    },

    getSnapshot(event) {
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/outlet-items/snapshot?barcode=${event}`
        )
        .then(response => {
          if (response.status === 200) {
            this.snapshot = response.data.data.snapshot
            this.search.barcode = ''
            this.getItems()
          }
        })
    },

    getCodeCatalog(event) {
      this.getSnapshot(event)
    },

    setTime() {
      const date = new Date()
      let hours = date.getHours()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()
      hours = hours <= 9 ? `${hours}`.padStart(2, 0) : hours
      minutes = minutes <= 9 ? `${minutes}`.padStart(2, 0) : minutes
      seconds = seconds <= 9 ? `${seconds}`.padStart(2, 0) : seconds
      this.hours = hours
      this.minutes = minutes
      this.seconds = seconds
    },

    getSalespersons() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/employees?role=SALESPERSON`)
        .then(response => {
          if (response.status === 200) {
            this.salespersons = response.data.data.employees
          }
        })
    },

    searchSalesperson(event) {
      this.salespersons_filtered = this.salespersons.filter(item => {
        return item.name.toLowerCase().startsWith(event.query.toLowerCase())
      })
    },

    getCustomers(name = '', no_telp = '', create = false) {
      if (no_telp != '') name = ''
      // check if no_telp has 0 in first word then change into 62
      if (no_telp != '' && no_telp[0] == '0') {
        no_telp = no_telp.replace('0', '62')
      }
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/customers?name=${name}&no_telp=${no_telp}&page=&limit=`
        )
        .then(response => {
          if (response.status == 200) {
            this.customers = response.data.data.customers
            // loop and check if phone number starts with '62', change it to '0'
            for (let i = 0; i < this.customers.length; i++) {
              if (
                this.customers[i].phone.startsWith('62') &&
                this.customers[i].phone != this.outlet.phone
              ) {
                this.customers[i].phone = '0' + this.customers[i].phone.slice(2)
              }
            }
            if (this.customers.length == 0) {
              this.isError.customers = true
            } else {
              this.isError.customers = false
              this.customersDefault(create)
            }
          }
        })
    },

    customersDefault(create) {
      if (this.customers_selected == null)
        this.customers_selected = this.customers.filter(
          item => item.phone == this.outlet.phone
        )[0]
      if (this.customers_selected == '') this.customers_selected = null
      if (create) this.customers_selected = this.customers[0]
    },

    searchCustomer() {
      let no_telp, name
      // check if this.selectedCustomer contains number assign into no_telp
      if (/^\d+$/.test(this.customers_selected)) {
        no_telp = this.customers_selected
      } else if (this.customers_selected instanceof Object) {
        name = this.customers_selected.name
      } else {
        name = this.customers_selected
      }
      this.getCustomers(name, no_telp)
    },

    createCustomer() {
      this.customer_isExist_dialog = false
      this.customer_dialog = true
      this.create_customer.phone = ''
      this.isError.customer_phone = false
      setTimeout(() => {
        this.customers_selected = null
        this.$refs.customer_phone.$el.focus()
      }, 500)
    },

    closeDialogCreateCustomer() {
      this.customer_dialog = false
      this.create_customer.phone = ''
      this.isError.customer_phone = false
      this.customersDefault('', this.outlet.phone)
      this.setFocus()
    },

    postCustomer() {
      if (this.create_customer.phone == '') {
        this.isError.customer_phone = true
        this.error_msg.customer_phone = 'Mohon isi telepon'
      }

      this.create_customer.type = 'REGULAR'
      this.create_customer.email = this.create_customer.phone

      this.loading.create = true
      if (!this.isError.customer_phone) {
        this.$http
          .post(
            `${process.env.VUE_APP_API_URL}/customers`,
            this.create_customer
          )
          .then(response => {
            if (response.status === 200) {
              this.customer_dialog = false
              this.getCustomers('', this.create_customer.phone, true)

              this.create_customer.phone = ''

              this.$toast.add({
                severity: 'success',
                summary: 'Berhasil menambahkan pelanggan',
                life: 3000
              })
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Gagal menambahkan pelanggan',
                life: 3000
              })
            }
          })
      }
    },

    getItems(event) {
      let page = 1
      if (event) {
        page = event.page + 1
      }

      this.loading.filter = true
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/outlet-items?page=${page}&limit=${this.item_limit}&name=${this.filter.name}&code=${this.filter.code}&barcode=${this.search.barcode}&supplier=${this.filter.supplier}`
        )
        .then(response => {
          this.loading.filter = false
          this.filter.name = ''
          this.filter.code = ''
          this.filter.supplier = ''
          if (response.status === 200) {
            this.items = response.data.data.items
            this.item_total = response.data.data.total
          }
        })
    },

    calculateTotalBayar() {
      let total_bayar = 0

      for (const payment of this.payment_temp) {
        total_bayar += payment.amount
      }

      this.total_bayar = this.total_billed_amount - total_bayar
      if (this.total_bayar < 0) {
        this.total_bayar = 0
      }
    },

    searchName() {
      this.loading.filter = true
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/outlet-items?name=${this.filter.name}`
        )
        .then(response => {
          this.loading.filter = false
          if (response.status === 200) {
            this.items = response.data.data.items
            this.item_total = response.data.data.total
          }
        })
    },

    searchCode() {
      this.loading.filter = true
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/outlet-items?code=${this.filter.code}`
        )
        .then(response => {
          this.loading.filter = false
          if (response.status === 200) {
            this.items = response.data.data.items
            this.item_total = response.data.data.total
          }
        })
    },

    searchSupplier() {
      this.loading.filter = true
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/outlet-items?supplier=${this.filter.supplier}`
        )
        .then(response => {
          this.loading.filter = false
          if (response.status === 200) {
            this.items = response.data.data.items
            this.item_total = response.data.data.total
          }
        })
    },

    searchBarcode() {
      //check the barcode if contain qty
      let arr = this.search.barcode.split('*')
      let bcode = this.search.barcode
      let qty = 1
      if (arr.length > 1) {
        bcode = arr[1]
        qty = arr[0]
      }
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/outlet-items?barcode=${bcode}`)
        .then(response => {
          if (response.status === 200) {
            this.items = response.data.data.items
            this.item_total = response.data.data.total

            if (this.items.length === 1) {
              let count = this.cart.filter(
                item => item.code === this.items[0].code
              ).length
              let datas = 0
              if (count >= 0) {
                for (const item of this.cart) {
                  if (item.code === this.items[0].code) {
                    datas += item.qty
                  }
                }
              }

              if (
                count == this.items[0].stock ||
                datas >= this.items[0].stock
              ) {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Qty melebihi stok',
                  life: 3000
                })
              } else {
                this.getSnapshot(bcode)
                this.addToCart(this.items[0], qty)
                this.discount_type = 'Persen'
                this.search.barcode = ''
              }
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Invalid Barcode',
                detail: 'This item not found',
                life: 3000
              })
            }
          }
        })
    },

    getPaymentMethods() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/payment-methods`)
        .then(response => {
          if (response.status === 200) {
            this.payment_methods = response.data.data.payment_methods.filter(
              item => item.name !== 'GIFT' && item.name !== 'VOUCHER'
            )
            this.payment_temp[0].payment_method_id =
              this.payment_methods.filter(item => item.name === 'CASH')[0]
          }
        })
    },

    checkPaymentMethod(index, item) {
      if (this.payment_temp.length > 1) {
        this.payment_pay = false
        for (let i = 0; i < index; i++) {
          if (this.payment_temp[i].payment_method_id.name == item.name) {
            this.$toast.add({
              severity: 'error',
              summary: 'Payment method sudah ada',
              detail: 'Tidak boleh sama',
              life: 3000
            })
            this.payment_temp[index].payment_method_id = ''
            return
          }
        }
      } else if (item.name != 'CASH') {
        this.payment_pay = true
      } else if (item.name == 'CASH') {
        this.payment_pay = false
      }
    },

    openCatalogList(value) {
      this.dialog.catalog = value
      setTimeout(() => {
        this.$refs.barcode_catalog.$el.focus()
      }, 500)
      this.getItems()
    },

    changeReset() {
      this.dialog.change = false
      this.money_changes = 0
      setTimeout(() => {
        this.$refs.barcode.$el.focus()
        this.customer_isExist_dialog = true
        // this.customers_selected = null
      }, 1000)
    },

    loadCatalog(data) {
      //check the barcode if contain qty
      let arr = this.search.barcode.split('*')
      let brcode = data.code
      let qty = 1
      if (arr.length > 1) {
        brcode = arr[1]
        qty = arr[0]
      }
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/outlet-items?barcode=${brcode}`)
        .then(response => {
          if (response.status === 200) {
            this.items = response.data.data.items
            this.item_total = response.data.data.total
            this.getSnapshot(brcode)

            if (this.items.length === 1) {
              let count = this.cart.filter(
                item => item.code === this.items[0].code
              ).length
              let datas = 0
              if (count >= 0) {
                for (const cartItem of this.cart) {
                  if (cartItem.code === this.items[0].code) {
                    datas += cartItem.qty
                  }
                }
              }
              if (
                count == this.items[0].stock ||
                datas >= this.items[0].stock
              ) {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Qty melebihi stok',
                  life: 3000
                })
              } else {
                this.addToCart(this.items[0], qty)
                this.discount_type = 'Persen'
                this.dialog.catalog = false
              }
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Stok barang sudah kosong',
                life: 3000
              })
            }
          }
        })
    },

    openTransactionList(value) {
      this.dialog.pending = value
      this.getTransactions()
    },

    getTransactions() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/pos/transactions`)
        .then(response => {
          if (response.status === 200) {
            this.transactions = response.data.data.transactions
          }
        })
    },

    loadTransaction(data) {
      this.dialog.pending = false
      this.cart = []
      this.transaction.id = data.id
      this.customers_selected = data.customer
      this.salespersons_selected = data.sales_person
      this.voucher_code = data.voucher.refund.code

      if (data.discount > 0) {
        this.discount_type = 'Rupiah'
        this.discount_transaction_percentage = parseInt(data.discount)
        this.discount_transaction_value = data.discount
      } else {
        this.discount_type = []
        this.discount_transaction_percentage = null
        this.discount_transaction_value = 0
      }

      data.items.forEach((items, index) => {
        this.cart.unshift({ ...items, iterasi: index + 1 })
      })

      this.checkcart()
      this.calculateDiscount()
      this.calculateTotal(true)

      if (this.voucher_code != '') {
        this.validateVoucher()
      } else {
        console.warn('No voucher founded')
      }
    },

    openDialogGiftClaim(value) {
      if (this.cart.length === 0) {
        this.openLinkTheSameTab('gift-claim', {})
      } else {
        this.dialog.confirmGiftClaim = value
      }
    },

    openDialogRefund(value) {
      if (this.cart.length === 0) {
        this.openLinkTheSameTab('refund', {})
      } else {
        this.dialog.confirmRefund = value
      }
    },

    openDialogStruk(value) {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/pos/invoices?status=1`)
        .then(response => {
          if (response.status === 200) {
            this.transaction_histories = response.data.data.invoices
            if (this.transaction_histories.length === 0) {
              this.dialog.emptyStruk = value
            } else if (this.cart.length === 0) {
              this.openLinkNewTab('receipt', {})
            } else {
              this.dialog.confirmStruk = value
            }
          }
        })
    },

    openDialogRingkasan(value) {
      if (this.cart.length === 0) {
        this.openLinkTheSameTab('summary', {})
      } else {
        this.dialog.confirmRingkasan = value
      }
    },

    openDialogLogout(value) {
      this.dialog.confirmLogout = value
    },

    checkcart() {
      const duplikatObj = [...new Map(this.cart.map(item => [item.id, item]))]
      this.lengthCart = duplikatObj.length
    },

    addToCart(value, initQty = 1) {
      this.iterasi = this.cart.length

      if (this.is_open_shift === true) {
        this.cart.unshift({
          iterasi: this.iterasi + 1,
          id: value.id,
          code: value.code,
          name: value.name,
          stock: value.stock,
          qty: parseInt(initQty),
          price: parseFloat(value.price),
          discount: parseFloat(value.discount)
        })
        this.checkcart()
        this.calculateDiscount()
        this.calculateTotal(false)
        this.changeDiscountTransaction()
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Mulai shift terlebih dahulu',
          life: 3000
        })
      }
    },

    checkDiscountVoucher() {
      if (this.cart.length === 0) {
        this.voucher_code = ''
        this.voucher_amount = 0
        this.cancelVoucher = false
      }
    },

    addQty(data) {
      let count = this.cart.filter(item => item.code === data.code).length
      let datas = 0
      if (count >= 0) {
        for (const item of this.cart) {
          if (item.code === data.code) {
            datas += item.qty
          }
        }
      }

      if (
        data.stock <= data.qty ||
        count == data.stock ||
        datas >= data.stock
      ) {
        this.$toast.add({
          severity: 'error',
          summary: 'Qty melebihi stok',
          life: 3000
        })
      } else {
        data.qty += 1
      }

      this.discount_type = []
      this.discount_transaction_value = 0
      this.discount_transaction_percentage = null

      this.calculateDiscount()
      this.checkcart()
      this.calculateTotal(true)
    },

    minQty(data) {
      if (data.qty >= 1) {
        data.qty -= 1
      }

      if (data.qty <= 0) {
        let idx = this.cart.indexOf(data)
        if (idx > -1) {
          this.cart.splice(idx, 1)
          this.snapshot = []
        }
      }

      this.discount_type = null
      this.discount_transaction_value = 0
      this.discount_transaction_percentage = null

      this.checkDiscountVoucher()
      this.checkcart()
      this.calculateDiscount()
      this.calculateTotal(true)
    },

    deleteItem(data) {
      let idx = this.cart.indexOf(data)
      if (idx > -1) {
        this.cart.splice(idx, 1)
        this.snapshot = []
        this.search.barcode = ''
      }

      this.discount_type = null
      this.discount_transaction_value = 0
      this.discount_transaction_percentage = null

      this.checkDiscountVoucher()
      this.checkcart()
      this.calculateDiscount()
      this.calculateTotal(false)
      this.changeDiscountTransaction()
    },

    changeItemDiscount() {
      this.calculateDiscount()
      this.calculateTotal(true)
    },

    changeDiscountTransaction() {
      let priceElement = document.getElementById('jumlah-diskon')
      let priceValue = priceElement.value.replace(/\./g, '')
      let parsedPrice = parseInt(priceValue)
      this.discount_transaction_percentage = parsedPrice ? parsedPrice : null

      if (
        this.discount_transaction_percentage >= 100 &&
        this.discount_type === 'Persen'
      ) {
        this.discount_transaction_percentage = 100
      }

      this.discount_transaction_value = 0
      if (this.discount_type === 'Persen') {
        if (this.discount_transaction_percentage <= 100) {
          this.discount_transaction_value =
            this.discount_transaction_percentage * (this.billed_amount / 100)
        } else {
          this.discount_transaction_percentage =
            (this.discount_transaction_percentage / this.billed_amount) * 100
          this.discount_transaction_value =
            this.discount_transaction_percentage * (this.billed_amount / 100)
        }
      } else if (this.discount_type === 'Rupiah') {
        this.discount_transaction_value = this.discount_transaction_percentage
      }

      this.calculateDiscount()
      this.calculateTotal(false)
    },

    changePaymentTransaction() {
      if (this.payment_method_selected.name != 'CASH') {
        this.payment_temp.amount[0] = this.total_billed_amount
        this.infaq_payment_amount = 0
        this.round_up_return_amount = 0
      }
    },

    calculateTotal(tampil) {
      this.billed_amount = 0
      this.total_billed_amount = 0
      this.sub_total = 0
      this.qty_total = 0
      let iterasi = this.cart.length
      for (const cartItem of this.cart) {
        cartItem.iterasi = iterasi--
        this.billed_amount += cartItem.qty * cartItem.price
        this.sub_total += cartItem.qty * cartItem.price
        this.qty_total += cartItem.qty
      }

      this.billed_amount = this.sub_total - this.total_discount
      this.total_billed_amount =
        this.billed_amount -
        parseInt(this.discount_transaction_value) -
        this.voucher_amount

      if (this.total_billed_amount < 0 && tampil) {
        this.$toast.add({
          severity: 'error',
          summary: 'Belanja anda kurang dari voucher',
          life: 3000
        })
      } else if (this.total_billed_amount < 0 && !tampil) {
        this.$toast.add({
          severity: 'error',
          summary: 'Belanja anda melebihi discount',
          life: 3000
        })
      }
    },

    calculateChange(index = 0) {
      let priceElement = document.getElementById('data-price-' + index)
      let priceValue = priceElement.value.replace(/\./g, '')
      let parsedPrice = parseInt(priceValue)

      if (index === 0) {
        this.payment_temp[0].amount = parsedPrice
      } else {
        this.payment_temp[index].amount = parsedPrice
      }

      let total_bayar = 0

      for (const payment of this.payment_temp) {
        total_bayar += payment.amount
      }

      this.calculateTotalBayar()

      if (total_bayar < this.total_billed_amount) {
        this.isError.payment_amount = true
        this.error_msg.payment_amount = 'Jumlah pembayaran tidak cukup'
        this.loading.payment = false
      } else {
        this.isError.payment_amount = false
        this.money_changes = total_bayar - this.total_billed_amount
        this.infaq_payment_amount = 0
        this.round_up_return_amount = 0
      }
    },

    calculateInfaq() {
      let total_bayar = 0

      for (const payment of this.payment_temp) {
        total_bayar += payment.amount
      }

      let kembalian = total_bayar - this.total_billed_amount
      if (this.infaq_payment_amount == null) {
        this.isError.infaq = false
        this.money_changes = kembalian
      } else if (
        this.infaq_payment_amount > kembalian ||
        this.infaq_payment_amount < 0
      ) {
        this.isError.infaq = true
        this.error_msg.infaq = 'Jumlah infaq melebihi kembalian'
        this.money_changes = kembalian
      } else {
        this.isError.infaq = false
        this.money_changes = kembalian - this.infaq_payment_amount
      }
    },

    calculateRoundUp() {
      let total_bayar = 0

      for (const payment of this.payment_temp) {
        total_bayar += payment.amount
      }

      let kembalian = total_bayar - this.total_billed_amount
      if (
        this.round_up_return_amount == null ||
        this.round_up_return_amount == 0
      ) {
        this.isError.round_up = false
        this.money_changes = kembalian
      } else {
        this.isError.round_up = false
        this.money_changes = kembalian + parseInt(this.round_up_return_amount)
      }
    },

    calculateDiscount() {
      this.total_discount = 0
      this.isError.discount_item = false
      for (const cartItem of this.cart) {
        if (cartItem.discount > 100) {
          this.isError.discount_item = true
          this.error_msg.discount_item = 'Diskon harus dibawah 100%'
        }
        this.total_discount +=
          cartItem.qty * cartItem.price * (cartItem.discount / 100)
      }
    },

    saveTransaction(isPayingMethod) {
      if (this.cart.length === 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Keranjang tidak boleh kosong',
          life: 3000
        })
        return
      }

      if (!this.customers_selected) {
        this.$toast.add({
          severity: 'error',
          summary: 'Pilih customer',
          life: 3000
        })
        return
      }

      // check if in transaction items.discount > 100 give error
      for (const item of this.cart) {
        if (item.discount > 100) {
          this.$toast.add({
            severity: 'error',
            summary: 'Diskon item harus dibawah 100%',
            life: 3000
          })
          return
        }
      }

      if (!this.salespersons_selected || this.salespersons_selected == null) {
        this.transaction.salesperson_id = null
      } else {
        this.transaction.salesperson_id = this.salespersons_selected.id
      }

      this.transaction.cust_id = this.customers_selected.id
      this.transaction.items = this.cart
      this.transaction.discount = this.discount_transaction_value

      if (this.voucher_code_used != null) {
        this.transaction.voucher_code = this.voucher_code_used
      }

      this.$http
        .post(
          `${process.env.VUE_APP_API_URL}/pos/transactions${
            this.transaction.id === 0 ? '' : '?id=' + this.transaction.id
          }`,
          this.transaction
        )
        .then(response => {
          if (response.status == 200) {
            if (isPayingMethod) {
              this.transaction.id = response.data.data.id
              this.calculateTransaction()
            } else {
              this.clearTransaction()
              setTimeout(() => {
                this.$refs.barcode.$el.focus()
              }, 1000)
              this.$toast.add({
                severity: 'success',
                summary: 'Berhasil menyimpan transaksi',
                life: 3000
              })
            }
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Gagal memproses transaksi',
              life: 3000
            })
          }
        })
    },

    calculateTransaction() {
      this.$http
        .post(
          `${process.env.VUE_APP_API_URL}/pos/transactions/${this.transaction.id}/calculate`
        )
        .then(response => {
          if (response.status == 200) {
            this.payment.invoice_id = response.data.data.invoice.id
            this.total_billed_amount =
              response.data.data.invoice.amount - this.voucher_amount
            this.payment_temp[0].amount = this.total_billed_amount
            this.money_changes = 0
            this.dialog.payment = true
            this.payment_pay = false
            // non-active error message
            this.nonActiveError()
            // filed jumlah bayar active
            setTimeout(() => {
              this.$refs.payment[0].$el.querySelector('.p-inputtext').focus()
            }, 500)
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Gagal memproses transaksi',
              life: 3000
            })
          }
        })
    },

    nonActiveError() {
      this.isError.payment_amount = false
      this.error_msg.payment_amount = ''
      this.isError.infaq = false
      this.error_msg.infaq = ''
      this.infaq_payment_amount = 0
      this.round_up_return_amount = 0
      this.total_bayar = 0

      let idx = this.payment_temp.indexOf(this.payment_temp[1])
      if (idx > -1) {
        this.payment_temp.splice(idx, 1)
      }
    },

    handleEnter() {
      if (!this.enterPressed) {
        this.enterPressed = true
        this.payTransaction()
      }
    },

    payTransaction() {
      if (this.infaq_payment_amount == '') {
        this.infaq_payment_amount = 0
      }
      this.payment.infaq = parseInt(this.infaq_payment_amount)
      this.payment.round_up = parseInt(this.round_up_return_amount)
      this.payment.change = parseInt(this.money_changes)
      this.payment.payment = this.payment_temp.map(item => {
        return {
          payment_method_id: item.payment_method_id.id,
          amount: item.amount
        }
      })
      this.payment.voucher_code = this.voucher_code

      let total_bayar = 0
      for (const payment of this.payment_temp) {
        total_bayar += payment.amount
      }

      if (total_bayar < this.total_billed_amount) {
        this.$toast.add({
          severity: 'error',
          summary: `Pembayaran tidak cukup`,
          life: 3000
        })
        return
      }

      if (this.isError.infaq) {
        this.$toast.add({
          severity: 'error',
          summary: 'Jumlah infaq melebihi jumlah uang yang dibayarkan',
          life: 3000
        })
        return
      } else {
        this.money_changes =
          parseInt(total_bayar) -
          parseInt(this.total_billed_amount) -
          parseInt(this.infaq_payment_amount) +
          parseInt(this.round_up_return_amount)
      }
      this.loading.payment = true
      this.$http
        .post(`${process.env.VUE_APP_API_URL}/pos/payments`, this.payment)
        .then(response => {
          if (response.status == 200) {
            this.enterPressed = false
            this.loading.payment = false
            this.dialog.payment = false
            this.dialog.change = true
            //print receipt
            this.openLinkNewTab('history-receipt', {
              id: this.payment.invoice_id
            })
            this.getTemporaryOmset()
            this.clearTransaction()
          } else if (response.data.message == 'error.payment.no_change') {
            this.loading.payment = false
            this.$toast.add({
              severity: 'error',
              summary: 'Tidak boleh ada kembalian',
              life: 3000
            })
          } else {
            this.loading.payment = false
            this.$toast.add({
              severity: 'error',
              summary: 'Gagal memproses transaksi',
              life: 3000
            })
          }
        })
    },

    addPaymentMethod() {
      this.payment_temp.push({
        payment_method_id: null,
        amount: 0
      })
      this.calculateChange()
    },

    removePaymentMethod(an_item) {
      let idx = this.payment_temp.indexOf(an_item)
      if (idx > -1) {
        this.payment_temp.splice(idx, 1)
      }
      this.calculateChange()
    },

    clearTransaction() {
      this.salespersons_selected = null
      this.customers_selected = null
      this.create_customer = {
        name: '',
        phone: '',
        email: '',
        type: 'REGULAR'
      }

      this.cart = []
      this.snapshot = []
      this.lengthCart = 0

      this.qty_total = 0
      this.sub_total = 0
      this.total_discount = 0
      this.billed_amount = 0
      this.total_billed_amount = 0
      this.infaq_payment_amount = 0
      this.round_up_return_amount = 0

      this.discount_type = null
      this.discount_transaction_percentage = null
      this.discount_transaction_value = 0

      this.getPaymentMethods()
      this.getCustomers('', this.outlet.phone)

      this.transaction = {
        id: '',
        salesperson_id: null,
        cust_id: null,
        items: [],
        discount: null
      }

      this.total_bayar = 0
      this.payment_temp = [
        {
          payment_method_id: null,
          amount: 0
        }
      ]
      this.payment = {
        invoice_id: 0,
        payment: [],
        infaq: 0,
        voucher_code: ''
      }

      this.payment_amount = 0
      this.bank_account = null

      this.clearVoucher()
    },

    openHistoryList(value) {
      this.dialog.history = value
      this.getListHistory()
    },

    getListHistory() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/pos/invoices?status=1`)
        .then(response => {
          if (response.status === 200) {
            let struk_histories = [...response.data.data.invoices]
            const duplikatStruk = [
              ...new Map(struk_histories.map(item => [item.id, item])).values()
            ]
            this.transaction_histories = duplikatStruk
          }
        })
    },

    confirmOpenShift() {
      this.opening_balance = this.user.shift.opening_balance
      this.dialog.open_shift = true
    },

    openShift() {
      this.dialog.open_shift = false

      this.$http
        .post(`${process.env.VUE_APP_API_URL}/shifts/open`, this.inputData)
        .then(response => {
          if (response.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Berhasil memulai shift',
              life: 3000
            })

            this.$store
              .dispatch('setUser', { requestOptions: { method: 'GET' } })
              .then(() => {
                this.getAuthUser()
              })
            this.customer_isExist_dialog
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Gagal memulai shift',
              life: 3000
            })
          }
        })
    },

    logout() {
      this.$store
        .dispatch('logout', { requestOptions: { method: 'POST' } })
        .then(() => {
          setTimeout(() => {
            this.$router.push({ name: 'login' })
            this.$router.go()
          }, 1000)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.required-label {
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-customer:hover {
  cursor: pointer;
}

.container {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.card-qty {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  padding: 1.05rem;
}

.card-qty-title {
  font-size: 18px;
  font-weight: 700;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 170px;
}

.section {
  background-color: #ffffff;
  padding: 10px;
  margin: 0px;
  border: 1px solid #ddd;
}

.snapshot-code {
  font-weight: 700;
  font-size: 18px;
  word-wrap: break-word;
  text-align: center;
  padding: 5px 10px 0px 10px;
  margin-bottom: 5px;
}

.code-kosong {
  padding: 70px 0px;
}

.snapshot-card {
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}

.snapshot-name-price {
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  text-align: center;
  margin: 5px 10px 0px 10px;
}

.snapshot-img {
  max-width: 100% !important;
  padding: 0px;
  height: 16vh;
  display: block;
  margin: auto;
}

.table-items:hover {
  background-color: rgba($color: #04bfe4, $alpha: 0.1);
}

.product-code {
  font-size: 11px;
  margin: 0px;
  font-weight: bold;
}
.product-name {
  font-size: 1.3rem;
  font-weight: 700;
}

.product-initPrice {
  font-size: 1.1rem;
  color: #eb1d36;
  text-decoration: line-through;
  font-style: italic;
}

.product-code-discount {
  background-color: #ffdddd;
  text-align: center;
  padding: 0.2rem;
  font-size: 11px;
  font-weight: 700;
  border-radius: 0.2rem;
  color: #eb1d36;
}

.product-price {
  font-size: 15px;
}

.product-description {
  margin: 0.4rem 0rem;
  text-align: center;
}

.product-badge {
  border-radius: 1px;
  padding: 0.25em 0.25rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.discount {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}

/deep/ .product-grid-item {
  margin: 0.5em;
  border: 0.5px solid #dee2e6;
  padding: 10px 8px !important;

  .product-grid-item-top,
  .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  img {
    width: 75%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
  }

  .product-grid-item-content {
    text-align: center;
  }

  .product-price {
    font-size: 1.3rem;
    text-align: center;
  }

  .product-stok {
    font-size: 1rem;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;

    img {
      width: 75%;
      margin: 2rem 0;
    }

    .product-list-detail {
      text-align: center;
    }

    .product-price {
      align-self: center;
    }

    .product-list-action {
      display: flex;
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
