<template>
  <div class="card">
    <Toast />

    <div class="title">Detail Stock Opname</div>

    <div class="p-grid">
      <div class="p-col-3">
        <label for="">Status</label>
        <div
          v-if="stock_opname.status == 'START'"
          style="color: green; font-weight: bold"
        >
          {{ stock_opname.status }}
        </div>
        <div
          v-else-if="stock_opname.status == 'END'"
          style="color: red; font-weight: bold"
        >
          {{ stock_opname.status }}
        </div>
        <div v-else style="color: blue; font-weight: bold">
          {{ stock_opname.status }}
        </div>
      </div>
      <div class="p-col-3">
        <label for="">Waktu Mulai</label>
        <div>{{ stock_opname.start | basicDateTime }}</div>
      </div>
      <div class="p-col-3">
        <label for="">Total Kekurangan</label>
        <div>{{ stock_opname.deficiency_total | number }}</div>
      </div>
      <div class="p-col-3">
        <label for="">Total Kelebihan</label>
        <div>{{ stock_opname.excess_total | number }}</div>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-3"></div>
      <div class="p-col-3">
        <label for="">Waktu Selesai</label>
        <div v-if="stock_opname.end != ''">
          {{ stock_opname.end | basicDateTime }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="p-col-3">
        <label for="">Nilai Kekurangan</label>
        <div v-if="stock_opname.status == 'START'">
          {{ stock_opname.replacement_price_deficiency | currency }}
        </div>
        <div v-else>
          {{ stock_opname.deficiency_value | currency }}
        </div>
      </div>
      <div class="p-col-3">
        <label for="">Nilai Kelebihan</label>
        <div>{{ stock_opname.excess_value | currency }}</div>
      </div>
    </div>

    <DataTable
      :value="stock_opname_detail"
      :paginator="true"
      class="p-datatable-outlets"
      :rows="limit"
      :totalRecords="totalRecords"
      :lazy="true"
      :rowHover="true"
      :loading="loading"
      @page="getReview($event)"
    >
      <template #header>
        <div class="p-grid p-fluid p-mb-2">
          <div class="p-col">
            <div>
              <span style="font-size: 16px">
                Jumlah Ganti :
                {{
                  data_opname_detail.total_replacement_price | currency
                }}</span
              >
            </div>
          </div>
        </div>
      </template>
      <template #empty>Tak ada data</template>
      <template #loading>Loading ...</template>

      <Column header="No" headerStyle="width: 3em">
        <template #body="slotProps">
          {{ stock_opname_detail.indexOf(slotProps.data) + 1 }}
        </template>
      </Column>
      <Column header="Kode Barang">
        <template #body="slotProps">
          <span>{{ slotProps.data.item_code }}</span>
        </template>
      </Column>
      <Column header="Nama Barang">
        <template #body="slotProps">
          <span>{{ slotProps.data.item_name }}</span>
        </template>
      </Column>
      <Column header="Selisih">
        <template #body="slotProps">
          <span v-if="slotProps.data.type == 'DEFICIENCY'"
            >- {{ slotProps.data.difference }}
          </span>
          <span v-else>{{ slotProps.data.difference }}</span>
        </template>
      </Column>
      <Column header="Harga Ganti">
        <template #body="slotProps">
          <span>{{ slotProps.data.replacement_price | currency }}</span>
        </template>
      </Column>
      <Column header="Jumlah Ganti">
        <template #body="slotProps">
          <span>{{ slotProps.data.total_replacement_price | currency }}</span>
        </template>
      </Column>
    </DataTable>

    <div
      class="p-grid p-fluid p-col-12 p-justify-center p-mt-4"
      v-if="stock_opname.status === 'END'"
    >
      <div class="p-col-12 p-md-6 p-lg-3">
        <router-link
          :to="{
            name: 'print-stock-opname',
            query: {
              id: this.$route.query.id,
              outlet_id: this.user.outlet_id,
              total: this.totalRecords
            }
          }"
          target="_blank"
        >
          <Button class="p-button-outlined" label="Print" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user', 'isAuthenticated'])
  },
  watch: {
    user() {
      this.getAuthUser()
    }
  },
  data() {
    return {
      stock_opname_id: 0,
      data_opname_detail: 0,

      excess_total: '',
      excess_value: '',
      stock_opname: {
        id: '',
        start: '',
        end: '',
        status: '',
        deficiency_total: '',
        deficiency_value: '',
        excess_total: '',
        excess_value: ''
      },
      stock_opname_detail: [],
      limit: 10,
      totalRecords: 0,
      loading: false
    }
  },
  mounted() {
    this.getAuthUser()
  },
  methods: {
    getAuthUser() {
      if (this.user && this.user.stock_opname) {
        this.stock_opname_id = this.user.stock_opname.id
        this.getReview()
      }
    },

    async getReview(event) {
      this.loading = true
      try {
        let page = 1
        if (event) {
          page = event.page + 1
        }

        const response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/pos/stock-opname/${this.$route.query.id}?limit=${this.limit}&page=${page}`
        )

        if (response.status === 200) {
          this.data_opname_detail = response.data.data
          this.stock_opname = this.data_opname_detail.stock_opname
          this.stock_opname_detail = this.data_opname_detail.stock_opname_detail
          this.totalRecords = this.data_opname_detail.total
        }
      } catch (error) {
        console.error('Error fetching review data:', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped></style>
