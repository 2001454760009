<template>
  <div class="card">
    <Toast />

    <div class="title">Buat Stock Opname</div>

    <div
      class="p-grid p-fluid p-mt-5"
      v-if="!stock_opname_status || stock_opname_status === 'END'"
    >
      <div class="p-col-12 p-md-6 p-lg-3">
        <Button label="Mulai Stock Opname" @click="start_dialog = true" />
      </div>
    </div>

    <div class="p-grid" v-else>
      <div class="p-grid p-col-12">
        <div class="p-col-4">
          <label for="">Status</label>
          <div
            v-if="stock_opname_status == 'START'"
            style="color: green; font-weight: bold"
          >
            {{ stock_opname_status }}
          </div>
          <div
            v-else-if="stock_opname_status == 'END'"
            style="color: red; font-weight: bold"
          >
            {{ stock_opname_status }}
          </div>
          <div v-else style="color: blue; font-weight: bold">
            {{ stock_opname_status }}
          </div>
        </div>
        <div class="p-col-4">
          <label for="">Tanggal Mulai</label>
          <div>{{ stock_opname_start }}</div>
        </div>
        <div class="p-col-4">
          <label for="">Tanggal Selesai</label>
          <div>-</div>
        </div>
      </div>

      <div class="p-col-12 p-formgroup-inline p-mt-5">
        <div class="p-field">
          <InputText
            v-model="barcode"
            placeholder="Barcode"
            ref="barcode"
            @keyup.enter="
              getItem()
              nextItemFocus($event)
            "
          />
        </div>
        <Button type="button" label="Cari" @click="getItem" class="p-mr-2" />
      </div>

      <div class="p-col-12 p-fluid">
        <table class="table table-responsive">
          <thead class="thead-light">
            <tr>
              <th>Gambar</th>
              <th>Kode</th>
              <th>Nama</th>
              <th class="text-center">Stok</th>
              <th>Real Stock</th>
              <th class="text-center">Selisih</th>
            </tr>
          </thead>
          <tbody v-if="item.id != 0">
            <tr>
              <td>
                <img
                  :src="item.thumbnail ? item.thumbnail : no_image"
                  style="width: 100px"
                  alt="item"
                />
              </td>
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td class="text-center" style="font-size: 18px">
                {{ item.stock }}
              </td>
              <td style="max-width: 80px">
                <InputText
                  :min="0"
                  type="number"
                  v-model="form.real_stock"
                  ref="real_stock"
                  style="font-size: 18px"
                  @keyup.enter="updateStockOpnameDetail()"
                />
              </td>
              <td class="text-center" style="font-size: 18px">
                {{ item.stock - form.real_stock }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="p-grid p-fluid p-col-12 p-justify-center" v-if="item.id != 0">
        <div class="p-col-12 p-md-6 p-lg-3">
          <Button type="button" label="Submit" @click="updateStockOpnameDetail">
            <span v-if="loading_submit">
              <i class="pi pi-spin pi-spinner" style="font-size: 12px"></i>
            </span>
          </Button>
        </div>
      </div>
    </div>

    <Dialog
      :visible.sync="start_dialog"
      :style="{ width: '450px' }"
      header="Konfirmasi"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-2 p-text-bold" />
        Kamu yakin ingin memulai proses stock opname?
      </div>
      <template #footer>
        <Button
          label="Tidak"
          icon="pi pi-times"
          class="p-button-text"
          @click="start_dialog = false"
        />
        <Button
          label="Ya"
          icon="pi pi-check"
          class="p-button-text"
          @click="startStockOpname()"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="retur_transfer_dialog"
      :style="{ width: '800px' }"
      header="Daftar Retur & Transfer Barang"
      :modal="true"
    >
      <DataTable
        :value="retur_transfer_data"
        :paginator="true"
        :lazy="true"
        class="p-datatable-outlets"
        :rows="limit"
        :totalRecords="totalRecords"
        @page="getReturTransferData($event)"
      >
        <Column header="Tipe">
          <template #body="slotProps">
            {{ slotProps.data.type }}
          </template>
        </Column>

        <Column header="Gudang">
          <template #body="slotProps">
            {{ slotProps.data.warehouse_name }}
          </template>
        </Column>

        <Column header="Nomor Bon">
          <template #body="slotProps">
            {{ slotProps.data.doc_number }}
          </template>
        </Column>

        <Column header="Tanggal Bon">
          <template #body="slotProps">
            {{ slotProps.data.doc_date }}
          </template>
        </Column>

        <Column header="Total Barang">
          <template #body="slotProps">
            {{ slotProps.data.total_item | number }}
          </template>
        </Column>

        <Column header="Total Harga">
          <template #body="slotProps">
            {{ slotProps.data.total_price | currency }}
          </template>
        </Column>

        <Column header="Status">
          <template #body="slotProps">
            <span
              :style="slotProps.data.status === 1 ? 'color:green' : 'color:red'"
            >
              <b>{{ checkStatus(slotProps.data.status) }}</b>
            </span>
          </template>
        </Column>
      </DataTable>
      <template #footer>
        <Button
          label="Close"
          icon="pi pi-times"
          class="p-mt-2"
          @click="retur_transfer_dialog = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import noImage from '../../assets/images/no-image.png'
export default {
  computed: {
    ...mapState(['user', 'isAuthenticated'])
  },
  watch: {
    user() {
      this.getAuthUser()
    }
  },
  data() {
    return {
      stock_opname_status: '',
      stock_opname_start: '',
      stock_opname_end: '',
      start_dialog: false,
      loading_submit: false,
      showScanner: false,
      barcode: '',
      item: {
        id: 0,
        name: '',
        image_url: '',
        category: '',
        supplier: '',
        stock: 0
      },
      form: {
        item_id: 0,
        real_stock: 0
      },
      no_image: noImage,
      retur_transfer_data: null,
      retur_transfer_dialog: false,
      limit: 10,
      totalRecords: 0
    }
  },
  mounted() {
    this.getAuthUser()
    setTimeout(this.setFocus, 1000)
  },

  methods: {
    setFocus() {
      if (this.$refs.barcode) {
        this.$refs.barcode.$el.focus()
      }
    },

    getAuthUser() {
      if (this.user && this.user.stock_opname) {
        this.stock_opname_status = this.user.stock_opname.status
        this.stock_opname_start = this.user.stock_opname.start
        this.stock_opname_end = this.user.stock_opname.end
      }
    },

    async startStockOpname() {
      this.start_dialog = false
      try {
        const response = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/pos/stock-opname/start`
        )

        if (response.status === 200) {
          this.showToast('success', 'Berhasil memulai stock opname')

          await this.$store.dispatch('setUser', {
            requestOptions: { method: 'GET' }
          })
          this.getAuthUser()

          setTimeout(() => {
            this.setFocus()
          }, 500)
        } else if (
          response.status === 500 &&
          response.data.message === 'error.retur_and_tranfer_not_completed'
        ) {
          this.showToast('error', 'Retur dan transfer barang belum selesai')
          this.retur_transfer_dialog = true
          this.getReturTransferData()
        } else {
          this.showToast('error', 'Gagal memulai stock opname')
        }
      } catch (error) {
        this.showToast('error', 'Terjadi kesalahan saat memulai stock opname')
        console.error(error)
      }
    },

    async getReturTransferData(event) {
      let page = 1
      if (event) {
        page = event.page + 1
      }
      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/dashboard/outlet-outbounds?receive_is_null=true&limit=${this.limit}&page=${page}`
        )

        if (response.status === 200) {
          this.retur_transfer_data = response.data.data.outlet_outbounds
          this.totalRecords = response.data.total
        }
      } catch (error) {
        console.error('Error fetching retur and transfer data:', error)
      }
    },

    checkStatus(status) {
      if (status == 1) {
        // return Confirmed Hijau
        return 'Confirmed'
      } else {
        // return Pending merah
        return 'Pending'
      }
    },

    async getItem() {
      this.item = {
        id: 0,
        name: '',
        image_url: '',
        category: '',
        supplier: '',
        stock: 0
      }
      this.form = {
        item_id: 0,
        real_stock: 0
      }

      if (this.barcode === '') {
        this.showToast('error', 'Barcode tidak boleh kosong')
        this.setFocus()
        return
      }

      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/outlet-items?show_empty_stock=true&barcode=${this.barcode}`
        )

        if (response.status === 200) {
          if (response.data.data.total === 0) {
            this.showToast('error', 'Barcode tidak ditemukan')
            this.clearForm()
            this.setFocus()
          } else {
            this.item = { ...this.item, ...response.data.data.items[0] }
            this.form = {
              item_id: response.data.data.items[0].id,
              real_stock: response.data.data.items[0].temporaryStock
                ? response.data.data.items[0].temporaryStock
                : 0
            }

            setTimeout(() => {
              this.$refs.real_stock.$el.focus()
            }, 100)
          }
        }
      } catch (error) {
        console.error('Error fetching item data:', error)
        this.showToast('error', 'Terjadi kesalahan saat mengambil data item')
      }
    },

    async getStockOpnameDetail() {
      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/pos/stock-opname-detail?item_id=${this.item.id}`
        )

        if (response.status === 200 && response.data.data.stock_opname_detail) {
          if (response.data.data.stock_opname_detail.type === 'DEFICIENCY') {
            this.form.real_stock -=
              response.data.data.stock_opname_detail.difference
          } else {
            this.form.real_stock +=
              response.data.data.stock_opname_detail.difference
          }
        }
      } catch (error) {
        // Handle error if needed
        console.error('Error fetching stock opname detail:', error)
      }
    },

    nextItemFocus(event) {
      console.log(
        event.target.parentElement.parentElement.nextElementSibling.children[0]
          .children[1].children[0].children[6].children[0]
      )
      event.target.parentElement.parentElement.nextElementSibling.children[0].children[1].children[0].children[6].children[0].focus()
    },

    async updateStockOpnameDetail() {
      this.form.item_id = this.item.id
      this.form.real_stock = parseInt(this.form.real_stock)

      if (this.form.real_stock < 0) {
        this.showToast('error', 'Real Stock tidak boleh kurang dari 0')
        return
      } else {
        this.loading_submit = true
      }

      try {
        const response = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/pos/stock-opname-details`,
          this.form
        )

        if (response.status === 200) {
          this.showToast('success', 'Berhasil menyimpan data stock opname')
          this.loading_submit = false
          this.clearForm()
          this.$refs.barcode.$el.focus()
        } else {
          this.showToast('error', 'Gagal menyimpan data stock opname')
          this.loading_submit = false
        }
      } catch (error) {
        this.showToast('error', 'Gagal menyimpan data stock opname')
        this.loading_submit = false
        console.error('Error saving stock opname data:', error)
      }
    },

    clearForm() {
      this.barcode = ''
      this.item = {
        id: 0,
        name: '',
        image_url: '',
        category: '',
        supplier: '',
        stock: ''
      }
      this.form = {
        item_id: 0,
        real_stock: 0
      }
      this.noImage = noImage
    },

    onDecode(data) {
      this.barcode = data
      this.getItem()
      this.showScanner = false
    }
  }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
